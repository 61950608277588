@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.player-badge {
  position: absolute;
  right: 0;
}

.card-group {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: center;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.sortedPlayers table td {
  white-space: nowrap;
}

.floatBtn {
  position: fixed !important;
  bottom: 125px;
  right: 20px;
}

.btnNavigation button svg {
  font-size: 3rem;
}